@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin banner($theme) {
    .banner {
        background-color: var.$primary;
        border-color: var.$primary;
        color: var.$primary;
        padding: 5px 10px;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        font-weight: normal;
        font-size: 14px;
        width: 100%;
        text-align: center;

        &.banner-primary {
            background-color: var.$primary-lighter;
            border-color: var.$primary;
            color: var.$primary-lighter-text;
        }

        &.banner-accent {
            background-color: var.$accent-lighter;
            border-color: var.$accent;
            color: var.$accent-lighter-text;
        }

        &.banner-accent-light {
            background-color: mix.lighten(var.$accent-lighter, 10%);
            border-color: var.$accent;
            color: var.$accent-lighter-text;
        }

        &.banner-warn {
            background-color: var.$warn-background;
            border-color: var.$warn-orange;
        }

        &.banner-warn-2 {
            background-color: var.$warn-2-lighter;
            border-color: var.$warn-2;
            color: var.$warn-2-darker;
        }

        &.banner-warn-3 {
            background-color: var.$transparent-red;
            border-color: var.$destructive-red;
            color: var.$primary;
        }

        &.banner-highlight {
            background-color: var.$highlight-lighter;
            border-color: var.$highlight;
            color: var.$highlight-lighter-text;
        }
        &.banner-grey {
            background-color: var.$light-background;
            border-color: var.$warn-2-darker;
            color: var.$primary-lighter-text;
            .mat-icon {
                color: var.$warn-2-darker;
            }
        }
        &.banner-warn-orange-darker {
            background-color: var.$warn-background-2;
            border: none;
            color: var.$primary;
        }

        &.text-left {
            text-align: left;
        }
    }
}
