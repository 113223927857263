@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;
@use 'sass:math';

@mixin mat-override($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $highlight: map-get($theme, highlight);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mat-flat-button,
    .mat-raised-button,
    .mat-fab,
    .mat-mini-fab {
        font-weight: 400;

        &.mat-primary {
            &:hover {
                background-color: var.$primary-darker;
                color: var.$primary-darker-text;
            }

            &[disabled] {
                background-color: mix.opaque(var.$primary, 50%);
                color: var.$primary-text;
            }
        }

        &.mat-accent {
            &:hover {
                background-color: var.$accent-darker;
                color: var.$accent-darker-text;
            }

            &[disabled] {
                background-color: mix.opaque(var.$accent, 50%);
                color: var.$accent-text;
            }
        }

        &.mat-warn {
            &:hover {
                background-color: var.$warn-darker;
            }

            &[disabled] {
                background-color: mix.opaque(var.$warn, 50%);
                color: var.$warn-text;
            }
        }

        &.mat-highlight {
            background-color: var.$highlight;
            color: #fff;
        }

        &.mat-danger {
            background-color: var.$warn;
            color: #fff;

            &[disabled] {
                background-color: mix.opaque(var.$warn, 50%);
                color: var.$warn-text;
            }
        }
    }

    .mat-stroked-button {
        font-weight: 400;

        &:not([disabled]) {
            background-color: white;

            &.mat-primary {
                border-color: var.$primary-lighter;
                color: var.$primary;

                &:hover {
                    background-color: mix.lighten(var.$primary, 90%);
                }
            }

            &.mat-accent {
                border-color: var.$accent-lighter;
                color: var.$accent;

                &:hover {
                    background-color: mix.lighten(var.$accent, 90%);
                }
            }

            &.mat-danger,
            &.mat-warn {
                border-color: var.$warn-lighter;
                color: var.$warn;

                &:hover {
                    background-color: mix.lighten(var.$warn, 90%);
                }
            }
        }

        &.mat-primary {
            &[disabled] {
                border-color: var.$primary-lighter;
                color: var.$primary-lighter;
            }
        }

        &.mat-accent {
            &[disabled] {
                border-color: var.$accent-lighter;
                color: var.$accent-lighter;
            }
        }
    }

    mat-button-toggle.mat-button-toggle {
        &.mat-button-toggle-checked {
            background-color: var.$accent;
            color: var.$accent-text;

            &:hover {
                background-color: var.$accent-darker;
            }

            .mat-button-toggle-focus-overlay {
                border-bottom: none;
            }
        }
    }

    .mat-checkbox.mat-checkbox-background-white .mat-checkbox-inner-container {
        background: white;
    }

    .mat-card-header-text-margin-0 .mat-card-header-text {
        margin: 0;
    }

    .mat-toolbar {
        background-color: mat.get-color-from-palette($background, card);
    }

    .mat-narrow {
        &.mat-flat-button,
        &.mat-stroked-button,
        &.mat-rised-button {
            line-height: 24px;
        }
    }

    .mat-tooltip.multiline {
        white-space: pre-wrap;
    }
    .mat-tooltip.text-center {
        text-align: center;
    }
    .mat-tooltip.fill-background {
        background-color: var.$tooltip-background;
    }

    .mat-tooltip.text-medium {
        font-size: 12px;
    }

    .mat-tooltip.tail {
        $tooltip-background-color: var.$tooltip-background;
        $tooltip-tail-width: 10px;
        $tooltip-tail-height: 10px;
        overflow: unset;
        position: relative;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
        }
        .mat-tooltip-panel-right &:before {
            border-top: math.div($tooltip-tail-height, 2) solid transparent;
            border-bottom: math.div($tooltip-tail-height, 2) solid transparent;
            border-right: $tooltip-tail-width solid $tooltip-background-color;
            border-left: 0;
            right: 100%;
            top: 50%;
            margin-top: math.div($tooltip-tail-height, 2) * -1;
        }
        .mat-tooltip-panel-left &:before {
            border-top: math.div($tooltip-tail-height, 2) solid transparent;
            border-bottom: math.div($tooltip-tail-height, 2) solid transparent;
            border-left: $tooltip-tail-width solid $tooltip-background-color;
            border-right: 0;
            left: 100%;
            top: 50%;
            margin-top: math.div($tooltip-tail-height, 2) * -1;
        }
        .mat-tooltip-panel-below &:before {
            border-style: solid;
            border-right: math.div($tooltip-tail-height, 2) solid transparent;
            border-left: math.div($tooltip-tail-height, 2) solid transparent;
            border-bottom: $tooltip-tail-width solid $tooltip-background-color;
            border-top: 0;
            left: 50%;
            bottom: 100%;
            margin-left: math.div($tooltip-tail-width, 2) * -1;
        }
        .mat-tooltip-panel-above &:before {
            border-style: solid;
            border-right: math.div($tooltip-tail-height, 2) solid transparent;
            border-left: math.div($tooltip-tail-height, 2) solid transparent;
            border-top: $tooltip-tail-width solid $tooltip-background-color;
            border-bottom: 0;
            left: 50%;
            top: 100%;
            margin-left: math.div($tooltip-tail-width, 2) * -1;
        }
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: mix.opaque(var.$accent, 54%);
    }
}


.mat-icon-button {
    &.mat-icon-button-small {
        $size: 20px;

        width: $size * 1.4;
        height: $size * 1.4;
        line-height: $size;

        .mat-icon {
            font-size: $size;
            width: $size;
            height: $size;
            line-height: $size;
        }
    }
}

aya-vendor-expense-form mat-datepicker-toggle button.mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 20px;
}

.mat-icon.mat-icon-small {
    $size: 20px;

    font-size: $size;
    width: $size;
    height: $size;
    line-height: $size;
}

.mat-icon.mat-icon-extra-small {
    $size: 16px;

    font-size: $size;
    width: $size;
    height: $size;
    line-height: $size;
}

.mat-icon.mat-icon-medium {
    $size: 24px;

    font-size: $size;
    width: $size;
    height: $size;
    line-height: $size;
}

.tooltip-shift-template-profession {
    font-size: 12px !important;
    height: 64px;
    width: 200px;
    white-space: normal;
    text-align: center;
}

.modal-wrapper {
    @at-root .modal-condensed & {
        .modal-body {
            padding: 16px 16px 24px 16px;
        }
    }
}

mat-checkbox {
    &.doc-checkbox-label {
        &.mat-checkbox {
            label {
                &.mat-checkbox-layout {
                    .mat-checkbox-label {
                        @include mix.font-14(400, var.$neutral-010);
                    }
                }
            }
        }
    }
}

.mat-select-search-inner .mat-select-search-input {
    background-color: white;
}

mat-optgroup.timecard-unit-group {
    span.mat-optgroup-label {
        white-space: normal;
        overflow-wrap: break-word;
        line-height: normal;
        height: auto;
        padding: 10px 16px 10px 16px;
    }
}
