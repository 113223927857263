/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/
@use '@angular/material' as mat;
@use 'src/styles/variables' as var;
@use 'src/styles/_mixins' as mix;
@use "palette" as pal;
@use "partials" as *;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@use "./extensions/index" as *;
@use 'domain' as env;


@mixin connect-theme() {
    // Compute font config
    /* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
    @include mat.legacy-core();
    @include mat.core;

    $custom-typography: mat.define-typography-config(
        $font-family: var.$font-family,
        $headline-1: mat.define-typography-level(34px, 40px, 600),
    );

    /* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
    @include mat.all-legacy-component-typographies($custom-typography);
    @include mat.all-component-typographies($custom-typography);

    // Theme Init
    /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
    @include mat.all-legacy-component-themes(pal.$theme);
    @include mat.all-component-themes(pal.$theme);
    @include portal-extensions(pal.$theme, pal.$altTheme);
    @include portal-partials(pal.$theme);

    iframe {
        border: 0;
    }

    // Specific component overrides, pieces that are not in line with the general theming

    // Handle buttons appropriately, with respect to line-height
    .mat-raised-button,
    .mat-stroked-button,
    .mat-flat-button {
        padding: 0 1.15em;
        margin: 0 0.65em;
        min-width: 3em;
        line-height: 36.4px;

        &.destructive, &.mat-destructive {
            color: var.$destructive-red;
            border: 1px solid var.$destructive-red;

            &:hover {
                background-color: var.$transparent-red;
            }

            &[disabled] {
                color: var.$light-red;
                border: 1px solid var.$light-red;
                background-color: var.$neutral-000;
            }
        }
    }

    .mat-standard-chip {
        padding: 0.5em 0.85em;
        min-height: 2.5em;
    }

    .mat-toolbar.mat-transparent {
        background-color: transparent;
    }

    .mat-slide-toggle {
        &-thumb {
            width: 26px;
            height: 26px;

            &-container {
                width: 24px;
                height: 24px;
                top: 1px;
                left: 1px;
            }
        }
        &-bar {
            border-radius: 13px;
            width: 46px;
            height: 26px;
        }
    }
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(19px, 0, 0);
    }

    .page-loading {
        position: fixed !important;
        margin-left: -14px;
        margin-top: -14px;
        margin-right: -14px;
        z-index: 10;
    }

    .ng-alert {
        box-sizing: border-box;
        padding: 15px;
        height: 45px;
        width: 100%;
        border-radius: 4px;
        font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
    }

    .ng-alert-info {
        background-color: rgba(var.$legend-yellow, 0.1);
        border: 1px solid var.$legend-yellow;
    }

    .ayac-button button {
        height: 34px;
        line-height: 32px;
        font-weight: normal;

        .mat-icon:first-child {
            margin-left: 0;
            margin-right: 9px;
            width: auto;
        }
        .mat-icon {
            margin-left: 9px;
            margin-right: 0;
            width: auto;
        }

        .mat-icon.fa,
        .mat-icon.fas,
        .mat-icon.far {
            margin-top: 5px;
        }
    }

    .ayac-button button span {
        display: -webkit-box;
    }

    .ayac-button button.ayac-button-block span {
        display: block;
    }

    .attachments-table.mat-tooltip {
        white-space: pre-line;
        font-size: 14px;
        text-align: center;
    }

    .staffing-advisory-list-table .large-row-cell {
        padding-bottom: 10px !important;
    }

    .card-section {
        .mat-card-header {
            align-items: center;
        }
        .mat-card-header .mat-card-title {
            margin-bottom: 0;
        }
        .mat-card-header-text {
            flex: 1 auto;
        }
        .mat-card-header,
        .mat-card-header:first-child {
            margin-top: -16px;
        }
    }

    // This is a global look we will want for tabs but limiting it for now so other components aren't changing unexpectedly.
    .submittal-details-tab-container {
        &.mat-tab-group.mat-primary .mat-ink-bar {
            background-color: var.$extra-orange;
            height: 5px;
        }

        .mat-tab-label, .mat-tab-label-active {
            opacity: 1;

            .mat-tab-label-content {
                color: #222222;
            }
        }

        .mat-tab-body-content {
            padding: 16px;
        }
    }

    @each $envName, $config in env.$domains {
        .env-#{$envName} {
            #signin-image {
                background-size: contain;
                background-image: url("/" + map-get($config, signin-logo));
                margin: 20px 0;

                &.signin-image-inverted {
                    background-image: url("/" + map-get(
                            $config,
                            signin-logo-inverted
                        ));
                    margin: 0 auto;
                    width: 100%;
                    height: 80px;
                }
            }
            #top-nav-logo div {
                min-width: 50px;
                height: 46px;
                margin: 0 auto;
                background-image: url("/" + map-get($config, logo));
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 0 0;
            }
            .loading-indicator:after {
                background-image: url("/" + map-get($config, loading));
            }
            .env-logo {
                background-image: url("/" + map-get($config, logo));
            }
            .env-full-logo {
                background-image: url("/" + map-get($config, signin-logo));
            }
        }
    }
    // Override Domains
    // @include domain-environments();
}

// styling for angularJS UI issue
.truncate-ui-text .ui-select-match-text {
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
}

.autoWidthCol.k-dropdown {
    width: auto;
}

.mat-tooltip.newline {
    white-space: pre-line;
}

.modal-wrapper {
    .modal-header {
        padding: 1rem 1rem 0.5rem 1rem;
        .top-button .close {
            width: 20px;
            height: 20px;
            span {
                font-weight: lighter;
                display: block;
                font-size: 32px;
                margin-top: -6px;
            }
        }
        .modal-title {
            padding: 0;
        }
    }
    .modal-body {
        padding: 1rem 1rem 2rem 1rem;

        .mdc-dialog__content {
            @include mix.font-14;
            padding: 0;
        }
    }
    .modal-footer {
        .mat-dialog-actions {
            button.mat-warn {
                background-color: var.$extra-red;
            }
            button.mat-warn:disabled {
                background-color: mix.opaque(var.$warn, 50%);
                color: var.$warn-text;
            }
        }
    }
}

.main-content {
    .vendor-expense-form {
        .mat-stroked-button.mat-accent {
            border-color: var.$accent;
            .mat-button-wrapper {
                display: flex;
                align-items: center;
            }
        }
    }
}
