@use '@angular/material' as mat;
@use 'styles/migrated-styles-theme';
@use 'styles/client-expenses.scss';
@use 'content/flex-layout';
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;
@use 'src/styles/css-variables' as set;
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import 'styles/theme';
@import 'styles/spacers';
@import '../home/assets/sass/libs/flexbox-mixin';
@import 'styles/grid';
@import 'styles/icons';
@import 'styles/flex-utils';
@import 'styles/autocomplete-pill-input';
@import 'styles/ngx-extended-pdf-viewer';
@import 'styles/ngx-editor';

@include connect-theme();
@include set.css-variables();

mat-form-field.filter-dropdown-padding {
    .mat-form-field-flex {
        padding-left: 8px;
    }
}

table th {
    text-align: left;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #777;
    border-radius: 10px;
}

*, *:before, *:after {
    box-sizing: border-box;
}

img {
    vertical-align: middle;
}

h1 {
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.1;
    color: inherit;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

p {
    margin: 0 0 10px
}

body {
    margin: 0;
    padding: 0;
    line-height: 1.42;
    font-family: var.$font-family;
}

.mainbar {
    margin-left: 0;
}

@media (min-width: 768px) {
    .main-content {
        position: absolute;
        left: 230px; //clear the left sidebar
    }
}

.section-header {
    background-color: #f5f5f5;
    border: 1px solid var.$border-color;
    color: #777;
    font-size: 18px;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
}
.spacer {
    flex: 1 1 auto;
}

.migrated-grid-top-toolbar {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;

    button + button.migrated-grid-toolbar-button {
        margin-left: 10px;
    }
}

.jbs__edit__icon {
    color: var.$accent;
    margin-left: 5px;
}

.ejm__modal-title {
    font-weight: 600;
}

.modal-sm {
    .modal-header {
        padding: 10px 16px;
    }
    .modal-title {
        font-size: 16px;
    }
    .ejm {
        &__position-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
        }
        &__icon-toggle {
            font-size: 24px;
            cursor: pointer;
        }
        &__input-text {
            width: 60px;
            text-align: center;
            font-size: 20px;
            margin: 0 15px;
            line-height: 1.75;
        }
    }
}

.toaster {
    background-color: var.$primary;
    .mat-button {
        color: white;
        text-transform: uppercase;
    }

    &-success {
        background-color: var.$success;
        color: var.$light-text;
    }
    &-info {
        color: var.$info-darker-text;
    }
    &-fail {
        background-color: var.$warn-darker;
        color: var.$light-text;
    }
    &.mat-snack-bar-container {
        margin-top: 55px !important;
    }
}

.clientExpenseDetailToaster {
    .mat-simple-snackbar {
        white-space: pre-wrap; //Whitespace is preserved by the browser. Text will wrap when necessary, and on line breaks
    }
}

.form-group {
    margin-bottom: 15px;
}

.main-content {
    width: calc(100% - 230px);
    height: calc(100% - 50px);
    padding: 16px;
    overflow-y: auto;
    margin-top: 4em;
    position: absolute;
    box-sizing: border-box;
}

.main-content:has(show-form .pdf-viewer) {
    padding: 0;
    margin-top: 3.8em;
    overflow-y: hidden;
}

.main-content-only {
    padding: 0;

    .main-content {
        width: 100%;
        height: 100%;
        padding: 0;
        overflow-y: scroll;
        left: 0;
        margin-top: unset;
    }
}

body.overhaul {
    margin: 0;
    // TODO This is a temporary fix for a Chrome bug version 83 https://support.google.com/chrome/thread/48974735?hl=en
    input[type='file']:focus,
    input[type='radio']:focus,
    input[type='checkbox']:focus {
        outline: none;
    }

    .k-dropdown-wrap > .k-input,
    .k-dropdown .k-dropdown-wrap .k-select,
    .k-list,
    .k-reset {
        background: white;
    }
}

.cn__page-controls {
    @include flexbox;
    @include justify-content(space-between);
    margin-bottom: 16px;
}

.menu-header {
    padding: 8px;
    background-color: var.$primary;
    color: var.$primary-text;
    margin-bottom: 8px;
    margin-top: -8px;
    font-size: 0.85em;

    &:before {
        content: '';
        position: absolute;
        height: 0;
        border-style: solid;
    }
}

.mat-icon.icon-lg, .icon-lg {
    font-size: 1.5em;
}

.mat-icon-lg {
    font-size: 1.9em;
}

.mat-menu-below {
    .menu-header:before {
        border-width: 0 5px 7px 5px;
        border-color: transparent transparent var.$primary transparent;
        top: -7px;
    }
    &.mat-menu-before {
        .menu-header:before {
            right: 15px;
        }
    }
    &.mat-menu-after {
        .menu-header:before {
            left: 15px;
        }
    }
}

.mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
}

.fx-spacer {
    flex: 1 auto;
}

.mat-nav-list a.link,
a.link {
    color: var.$accent-darker;
    &:hover {
        color: var.$accent;
    }
}
.mat-list-small {
    &.mat-list-base .mat-list-item.mat-list-item-with-avatar,
    &.mat-list-base .mat-list-option.mat-list-item-with-avatar {
        height: 48px;
    }
    &.mat-list-base .mat-list-item .mat-list-item-content,
    &.mat-list-base .mat-list-option .mat-list-item-content {
        padding: 0 4px;
    }
}

.mat-menu-panel {
    background-color: var.$neutral-000;

    button.mat-menu-item {
        background-color: var.$neutral-000;

        &:hover {
            background-color: rgba(0, 0, 0, .04);
        }
    }

    &.mat-menu-before {
        margin-top: 10px;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 10px 6px;
            border-color: transparent transparent #ffffff transparent;
            position: absolute;
            top: 0;
            right: 1em;
        }
    }
}


.cn__section {
    border: 1px solid var.$border-color;
    background-color: white;
    &__header {
        background-color: var.$accent;
        border: 1px solid var.$accent;
        color: var.$primary-text;
        padding: 0 16px;
        min-height: 51px;
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        &__title {
            font-size: 18px;
            margin: 0;
            font-weight: 400;
        }
    }
    &__content {
        padding: 16px;
        color: var.$dark-primary-text;
    }
}

.cn__sub-section {
    margin-bottom: 20px;
    background-color: var.$neutral-000;
    border: 1px solid var.$border-color;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    &__header {
        color: #333333;
        background-color: #f5f5f5;
        border-color: var.$border-color;
        padding: 10px 15px;
        border-bottom: 1px solid var.$border-color;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        &__title {
            font-size: 14px;
            margin: 0;
            font-weight: 400;
        }
    }
    &__content {
        padding: 16px;
    }
}

.jba__list {
    padding-left: 0;
    margin: 10px 0 20px;
    list-style: none;
    &__item {
        position: relative;
        display: block;
        padding: 10px 8px;
        margin-bottom: -1px;
        color: #3f3f3f;
    }
}

.jba__tier__btn {
    min-width: 124px;
    padding: 3px;
    font-weight: 500;
    &--cta {
        min-width: 160px;
        font-size: 14px;
        padding: 5px;
    }
    &--cancel {
        background-color: transparent;
        color: var.$primary;
    }
}

ayac-tier-row-body {
    flex: 1;
    position: relative;
}

/* Temporary Facility Unit Rule */
.faun {
    &__sub-section {
        &__header {
            @include flexbox;
            padding: 10px 0;
            &__heading {
                padding: 0 15px;
                &--left {
                    flex: 0 0 30%;
                }
                &--right {
                    flex: 1;
                }
            }
        }
        &__content {
            @include flexbox;
            padding: 0;
            &__area {
                padding: 16px;
                &--left {
                    flex: 0 0 30%;
                }
                &--right {
                    flex: 1;
                }
            }
        }
    }
    &__unit-tip {
        font-style: italic;
        margin-left: 15px;
        font-size: 12px;
    }
}

.toolbar-group {
    font-size: var.$font-size-subheading-1;
    line-height: 1.5;
    margin: 0 2em;

    &-label {
        font-size: var.$font-size-caption;
        color: var.$dark-disabled-text;
        display: block;
    }
}

.separator {
    border-bottom: 1px solid var.$dark-dividers;
}
.full-width-field {
    width: 100%;
    .mat-form-field-infix {
        width: 100%;
    }
}
.k-form-inline .k-form-field {
    flex-wrap: wrap;
}

label.k-form-field .k-input {
    font-weight: bold;
}

.k-form-inline div.k-form-field > span:not(.k-widget),
.k-form-inline div.k-form-field > label:not(.k-widget),
.k-form-inline label.k-form-field > span:not(.k-widget) {
    text-align: left;
    width: 30%;
    padding: 5px 0;
    padding-right: 12px;
}
.k-form-inline .k-form-field > .k-combobox,
.k-form-inline .k-form-field > .k-textarea,
.k-form-inline .k-form-field > .k-dropdown-wrap,
.k-form-inline .k-form-field > .k-timepicker,
.k-form-inline .k-form-field > .k-numerictextbox,
.k-form-inline .k-form-field > .k-multiselect {
    flex: 1 1 auto;
    width: auto;
}

.k-form-inline .k-form-field > .k-numerictextbox {
    height: 2em;

    .k-input-spinner button {
        height: 0px;
    }
}

.k-form-inline .k-form-field > .k-timepicker {
    font-weight: bold;
}

.k-form-inline .k-form-field-static > label:not(.k-widget),
.k-form-inline .k-form-field-static > span:not(.k-widget) {
    align-self: flex-start;
}
.k-form-inline .k-form-field-static > div:not(.k-widget) {
    padding: 5px 0;
}
.k-form-inline .k-form-field > span:first-child {
    font-weight: bold;
}

.k-form-inline .k-form-field-caption {
    width: 100%;
    margin-top: 0.3em;
}
.k-form-inline .k-form-field > span:not(.k-widget) ~ .k-form-field-caption,
.k-form-inline .k-form-field > label:not(.k-widget) ~ .k-form-field-caption {
    margin-left: 30%;
}

.cdk-overlay-container {
    z-index: 10000 !important;
}
// Fix legacy icons
.k-icon:before {
    font-size: 16px;
}
.k-clear-value {
    font-size: 16px;
}

.k-calendar .k-state-selected .k-link {
    border-color: var.$accent;
    color: var.$accent-text;
    background-color: var.$accent;
}

.error-panel {
    border: 1px solid var.$warn;
    background-color: mix.lighten(var.$warn, 30%);
    border-radius: 4px;
    padding: 16px 16px;
}

.k-animation-container .k-list-container {
    min-width: 0;
}

.hide-selected .k-list li.k-selected {
    display: none;
}

.mat-checkbox-layout .mat-checkbox-label {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.warning-badge {
    background-color: var.$warn-orange;
    font-size: 14px;
    color: var.$light-text;
    border-radius: 11px;
    width: 18px;
    height: 18px;
    display: inline-block;
    line-height: 19px;
    text-align: center;

    &.badge-left {
        margin-right: 4px;
    }

    &.badge-right {
        margin-left: 4px;
    }
}

.warning-area {
    background-color: var.$warn-orange-lighter;
    border: 1px solid var.$warn-orange;
    border-radius: 4px;
    padding: 10px 20px;
}

.k-action-buttons .k-primary {
    color: var.$accent;
}

.k-action-buttons .k-primary:hover {
    color: #fff;
    background-color: var.$accent !important;
}

.aya__link {
    color: var.$accent;
    cursor: pointer;
}

.aya__cursor {
    cursor: pointer;
}

.connect-selected-item {
    border-width: 2px;
    border-style: solid;
    transition: background 0.2s ease !important;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    text-decoration: underline;
}

.invisibly {
    visibility: hidden !important;
}
.hidden {
    display: none !important;
}

.count-marker {
    background-color: var.$neutral-000;
    color: var.$extra-blue;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    line-height: 2;
    font-size: 12px;
    border-radius: 50%;
}

#top-nav-logo.brand-logo {
    div {
        display: none;
    }
    img {
        height: 40px;
        width: auto;
    }
}
.simple-table {
    width: 100%;
    tr.mat-header-row {
        th {
            color: var.$primary;
            font-weight: bold;
            font-size: 1em;
        }
    }
    tr.mat-row {
        td {
            padding: 5px;
            vertical-align: initial;
        }
    }
}

span.k-chip-label.k-text-ellipsis {
    word-wrap: break-word;
    text-overflow: unset;
    white-space: break-spaces;
    text-align: center;
    line-height: 1.5em;
}

span.k-icon.k-i-x-circle {
    opacity: 0.6;
}

span.k-icon.k-i-x-circle::before {
    content: "\e11b";
}

.k-multiselect.limited-height {
    max-height: 300px;
    overflow-y: auto;
}

.remove-selection-decoration .k-list li[role=option].k-selected {
    background-color: unset;
    color: black;

    &:hover {
        background-color: #f0f0f0;
        color: #424242;
    }
}

.dropdown-header-btn {
    width: 100%;
    border-radius: 0;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
}

.required-label::before {
    content: '*';
    color: #d9534f;
    font-size: 2em;
    line-height: 1em;
}

.image-crop-backdrop-background {
    background: rgba(0, 0, 0, 0.5);
}

.mat-tooltip {
    background-color: rgba(0,0,0, .5);
}

.mat-tooltip.large-tooltip {
    font-size: 14px;
    max-width: 700px;
}

.job-custom-field-dropdown {
    padding-top: 8px;
    formly-wrapper-kendo-form-field {
        kendo-formfield {
            div {
                flex: auto;
            }
        }
    }
}

.checkbox-error .mat-checkbox-frame {
    border-color: red;
}


.mat-tooltip.tooltip-shrunken {
    text-align: center;
    max-width: 140px;
    margin-left: 0;
    margin-right: 0;
}

.none-text {
    color: var.$neutral-006;
    font-style: italic;
}


.slim-field {
    @include mat.form-field-density(-5);
}
.slim-menu {
    @include mat.menu-density(-5);
}
