@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin nav-menu-override($theme) {
    .mat-drawer {
        .menu-item__icon {
            background-color: var.$sidebar;
        }
        .mat-toolbar {
            background-color: mix.lighten(var.$sidebar, 10%) !important;
        }
    }

    .menu-item {
        color: var.$sidebar-text;

        &__icon {
            background-color: var.$sidebar;
        }

        &__content {
            border-color: var.$sidebar;
        }

        &__content:hover {
            border-color: var.$sidebar-text;
        }

        &--hovered {
            .menu-item__content {
                border-color: var.$sidebar-text;
            }
        }

        &--module-active {
            color: white;
        }
        &--module-active .menu-item__content {
            border-color: var.$highlight;
        }

        &--item-active {
            background-color: mix.lighten(var.$sidebar, 80%);
        }
    }

    .menu-popup__panel {
        background-color: #fff;
    }

    .menu-popup__heading {
        color: var.$primary;
    }
}
