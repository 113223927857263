@use 'variables' as var;

@mixin mat-icon-size($size) {
    font-size: $size;
    width: $size !important;
    height: $size !important;
    min-height: $size;
    min-width: $size;
    line-height: $size !important;
}

@mixin linear-animation($time) {
    background-color: rgba(0,0,0,0.1);
    -webkit-transition: background-color $time linear;
    -ms-transition: background-color $time linear;
    transition: background-color $time linear;
}

@mixin mobile-screen {
    @media (min-width: #{var.$responsive-phone}) {
        @content;
    }
}
@mixin tablet-screen {
    @media (min-width: #{var.$responsive-tablet}) {
        @content;
    }
}
@mixin notebook-screen {
    @media (min-width: #{var.$responsive-notebook}) {
        @content;
    }
}

@mixin desktop-screen {
    @media (min-width: #{var.$responsive-desktop}) {
        @content;
    }
}

@function opaque($color, $alpha) {
    @return color-mix(in srgb, $color, transparent  $alpha);
}

@function lighten($color, $alpha) {
    @return color-mix(in srgb, $color, white  $alpha);
}

@function darken($color, $alpha) {
    @return color-mix(in srgb, $color, black  $alpha);
}

@mixin border-darker($position, $color: var.$border-color-2, $width: 1px, $style: solid) {
    @if $position == top {
        border-top: $width $style $color;
    } @else if $position == bottom {
        border-bottom: $width $style $color;
    } @else if $position == left {
        border-left: $width $style $color;
    } @else if $position == right {
        border-right: $width $style $color;
    } @else if $position == all {
        border: $width $style $color;
    }
}

@mixin font-12($weight: 400, $color: var.$primary, $style: normal) {
    font: $style $weight 12px/14px var.$font-family;
    color: $color;
    letter-spacing: normal;
}

@mixin font-14($weight: 400, $color: var.$primary, $style: normal) {
    font: $style $weight 14px/16px var.$font-family;
    color: $color;
    letter-spacing: normal;
}

@mixin font-15($weight: 400, $color: var.$primary, $style: normal) {
    font: $style $weight 15px/17px var.$font-family;
    color: $color;
    letter-spacing: normal;
}

@mixin font-16($weight: 400, $color: var.$primary, $style: normal) {
    font: $style $weight 16px/18px var.$font-family;
    color: $color;
    letter-spacing: normal;
}
