//BEGIN for text-area-with-counter component
.editor-container .NgxEditor {
    min-height: 150px;
    resize: vertical;
    overflow: auto;

    .NgxEditor__Content {
        min-height: 145px;
    }   
}
//END for text-area-with-counter component

