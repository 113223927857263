@use '@angular/material' as mat;

@mixin css-variables() {
    :root {
        --primary: #54565a;
        --primary-200: #ccccce;
        --primary-800: #3a3c3f;
        --primary-text: #ffffff;
        --primary-lighter-text: #000000;
        --primary-darker-text: #ffffff;

        --accent: #4690b0;
        --accent-200: #c8dee7;
        --accent-800: #2f7397;
        --accent-text: #ffffff;
        --accent-lighter-text: #ffffff;
        --accent-darker-text: #ffffff;

        --highlight: #f88529;
        --highlight-200: #fddabf;
        --highlight-800: #f56819;
        --highlight-text-active: #ffffff;
        --highlight-text: #eeeeee;
        --highlight-lighter-text: #000000;
        --highlight-darker-text: #ffffff;

        --sidebar-color: #54565a;
        --sidebar-lighter-color: #ccccce;
        --sidebar-darker-color: #3a3c3f;
        --sidebar-text-active: #ffffff;
        --sidebar-text: #D2D0CF;
        --sidebar-lighter-text: #000000;
        --sidebar-darker-text: #ffffff;

        --vendor-sidebar-color: #000000;
        --vendor-sidebar-lighter-color: #000000;
        --vendor-sidebar-darker-color: #000000;
        --vendor-sidebar-text-active: #ffffff;
        --vendor-sidebar-text: #D2D0CF;
        --vendor-sidebar-lighter-text: #ffffff;
        --vendor-sidebar-darker-text: #ffffff;

        --card-background: #c8dee74d;
        --card-chevron: #c8dee7;

        --danger-color: #f88529;
        --danger-lighter-color: #fddabf;
        --danger-darker-color: #f56819;
        --danger-text: #ffffff;
        --danger-lighter-text: #000000;
        --danger-darker-text: #ffffff;

        --warn-color: #ff5252;
        --warn-lighter-color: #ffcbcb;
        --warn-darker-color: #ff3838;
        --warn-text: #ffffff;
        --warn-lighter-text: #000000;
        --warn-darker-text: #ffffff;
        --warn-background-color: #fef8ea;

        --warn-alt-color: #f4d68f;
        --warn-alt-lighter-color: #f4efe4;
        --warn-alt-darker-color: #868583;

        --success-color: #4caf50;
        --success-lighter-color: #c9e7cb;
        --success-darker-color: #339637;
        --success-text: #ffffff;
        --success-lighter-text: #000000;
        --success-darker-text: #ffffff;

        --info-color: #2a7393;
        --info-lighter-color: #50b1db;
        --info-darker-color: #1e576f;
        --info-text: #ffffff;
        --info-lighter-text: #000000;
        --info-darker-text: #ffffff;

        --background-light-color: #f6f6f6;
        --background-dark-color: #2c2c2c;

        --text-light-color: #000000;
        --text-dark-color: #ffffff;

        --border-color: #dddddd;
        --border-color-2: #dadada;
        --link-color: #4690b0;
        --active-menu-item: #FEF6EF;

        --color-neutral-000: #ffffff;
        --color-neutral-001: #f9fafa;
        --color-neutral-002: #f5f5f5;
        --color-neutral-003: #eaeaea;
        --color-neutral-004: #cdcdcd;
        --color-neutral-005: #b0b0b0;
        --color-neutral-006: #767676;
        --color-neutral-007: #606060;
        --color-neutral-008: #444444;
        --color-neutral-009: #222222;
        --color-neutral-010: #4C4C4C;

        --file-upload-container-border-color:#CFD8DC;
        --file-upload-container-background-color: #ECEFF1;

        --toolbar-container-background-color: #FFFFFF;
    }
}
