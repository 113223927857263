@use 'variables' as var;

$spacers: (
    (0, 0),
    (var.$spacer * .25, var.$spacer * .25),
    (var.$spacer * .5, var.$spacer * .5),
    (var.$spacer, var.$spacer),
    (var.$spacer * 1.5, var.$spacer * 1.5),
    (var.$spacer * 3, var.$spacer * 3)
);

.ma-auto {
  margin-top: auto !important;
  margin-right: auto !important;
  margin-bottom: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

$i: 0;
@each $spacer-x, $spacer-y in $spacers {
  .ma-#{$i} {
    margin: $spacer-y $spacer-x !important;
  }

  .my-#{$i} {
    margin-top: $spacer-y !important;
    margin-bottom: $spacer-y !important;
  }

  .mx-#{$i} {
    margin-left: $spacer-x !important;
    margin-right: $spacer-x !important;
  }

  .mt-#{$i} {
    margin-top: $spacer-y !important;
  }

  .mr-#{$i} {
    margin-right: $spacer-x !important;
  }

  .mb-#{$i} {
    margin-bottom: $spacer-y !important;
  }

  .ml-#{$i} {
    margin-left: $spacer-x !important;
  }

  .pa-#{$i} {
    padding: $spacer-y $spacer-x !important;
  }

  .py-#{$i} {
    padding-top: $spacer-y !important;
    padding-bottom: $spacer-y !important;
  }

  .px-#{$i} {
    padding-left: $spacer-x !important;
    padding-right: $spacer-x !important;
  }

  .pt-#{$i} {
    padding-top: $spacer-y !important;
  }

  .pr-#{$i} {
    padding-right: $spacer-x !important;
  }

  .pb-#{$i} {
    padding-bottom: $spacer-y !important;
  }

  .pl-#{$i} {
    padding-left: $spacer-x !important;
  }

  $i: $i + 1;
}

.h-auto {
  height: auto!important
}
.w-auto {
  width: auto!important
}
