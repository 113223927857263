@mixin skeleton($theme) {
    .skeleton-overlay {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;        
            background: linear-gradient(90deg, #ededed 25%, #f3f3f3 37%, #ededed 63%);
            background-size: 400% 100%;
            animation: skeleton-loading 1.4s ease infinite;
        }
    }
    
    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%
        }

        100% {
            background-position: 0 50%
        }
    }  
}