@use 'src/styles/variables' as var;

.clientExpenseModal {
    .input-counter {
      font-size: 0.688rem;
      padding-right: 0.5rem;
      padding-top: 0.25rem;
    }
    .d-flex {
      display: block;
      .k-textarea {
        width: 100%;
        display: block;
        font-family: Arial, sans-serif;
        min-height: 4.375rem;
        border-radius: 0.313rem;
        resize: none;
      }
    }
    .mr-4 {
      font-weight: normal;
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

.cap-warning {
    display: flex;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    align-items: center;
    margin-left: 0.25rem;
    color: var.$warn-orange;
}


.cap-warning-resize {
    width: 1.5rem;
    height: 1.5rem;
}

.detail-content-wrap {
    overflow-wrap: anywhere;
    gap: 0.5rem;
}

.detail-item {
    display: flex;
    margin-bottom: 0.9rem;
    color: var.$primary;
}

.detail-item-wrap {
    display: flex;
    flex-wrap: wrap;
}

.mat-card {
    margin-top: 1rem;
    border: 1px solid var.$border-color;
    border-radius: 0.625rem;
}

.separator {
    margin-left: -1rem;
    margin-right: -1rem;
}
.mat-card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: var.$primary;
}

strong {
    width: 10rem;
    min-width: 10rem;
    color: var.$primary;
}

.mat-divider {
    margin-bottom: 1.5rem;
}

.header {
    display: flex;
    align-items: center;
    padding-top: 1rem;
}

.header-title {
    font-size: 2rem;
    font-weight: 700;
    line-height:2.25rem;
    font-family: Arial, sans-serif;
    letter-spacing: 0;
    color: var.$primary;
    margin-right: 1rem;
}

.tool-tip {
    display: flex;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    color: var.$accent;
}
