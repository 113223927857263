@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin dashboard-theme($theme) {
    $count-color: #414141;
    $title-color: #999CA2;
    $disabled-card-background: #EFEFF0;
    $disabled-icon-background: #D2D0CF;
    $disabled-icon-color: #FFFFFF;
    $disabled-count-color: #afafb0;
    $disabled-title-color: #999CA1;

    .to-do-card {
       background-color: var.$card-background;

        &__icon  mat-icon {
            color: var.$card-icon-color;
            background-color: var.$card-icon-background;
        }

        &__count {
            color: $count-color;
        }

        &__title {
            color: $title-color;
        }

        &__chevron {
            color: var.$card-chevron;
        }
    }

    .to-do-card.disabled{
        background-color: $disabled-card-background;

        .to-do-card__icon {
            mat-icon {
                color: $disabled-icon-color;
                background-color: $disabled-icon-background;
            }
        }
        .to-do-card__count {
            color: $disabled-count-color;
        }
        .to-do-card__title {
            color: $disabled-title-color;
        }
    }

}
