@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;
@use "@progress/kendo-theme-default/scss/all.scss" as *;

@mixin kendo-override($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .k-button.k-state-selected,
    .k-button.k-primary:active,
    .k-button.k-primary.k-state-active,
    .k-button.k-primary.k-state-selected,
    .k-button-group .k-button:active,
    .k-button-group .k-button.k-state-active,
    .k-button-group .k-button.k-state-selected,
    .k-button-group>input[type="radio"]:checked+.k-button,
    .k-button-group>input[type="checkbox"]:checked+.k-button,
    .k-action-buttons .k-primary:active,
    .k-action-buttons .k-primary.k-state-active,
    .k-editor-toolbar a.k-tool.k-state-selected,
    .k-button.k-selected {
        background-color: var.$accent;
        border-color: var.$accent;
    }

    .k-pager-numbers button.k-button-flat {
        color: mat.get-color-from-palette($foreground, text);
        border-color: mat.get-color-from-palette($foreground, divider) !important;
        border-width: 1px;
        border-style: solid;
        margin: 0 1px;
    }

    kendo-pager-next-buttons button.k-button-flat,
    kendo-pager-prev-buttons button.k-button-flat {
        border-color: mat.get-color-from-palette($foreground, divider) !important;
        border-width: 0px;
        border-style: solid;
        color: mat.get-color-from-palette($foreground, text) !important;
        margin: 0 1px;
        padding: 8px;
    }

    .k-pager-numbers .k-link:hover,
    kendo-pager-next-buttons span.k-link:hover,
    kendo-pager-prev-buttons span.k-link:hover {
        background-color: #ededed;
        color: var.$accent;
    }

    div.k-pager-numbers button[type=button].k-button-flat.k-button.k-selected {
        // Kendo has an embedded !important part of their CSS and we need to override it
        background-color: var.$accent !important;
        color: var.$accent-text;
    }

    .k-pager-nav:hover {
        color: var.$accent;
    }

    .k-grid-header .k-i-sort-asc-sm,
    .k-grid-header .k-i-sort-desc-sm,
    .k-grid-header .k-sort-order {
        color: var.$accent;
    }

    .k-calendar .k-footer .k-nav-today,
    .k-calendar .k-calendar-header .k-today,
    .k-calendar .k-footer .k-nav-today:hover,
    .k-calendar .k-footer .k-nav-today:focus,
    .k-calendar .k-calendar-header .k-today:hover,
    .k-calendar .k-calendar-header .k-today:focus {
        color: var.$accent;
    }

    .k-calendar .k-content .k-today {
        color: var.$accent;
    }

    .k-calendar.k-calendar-range .k-range-start .k-link,
    .k-calendar.k-calendar-range .k-range-end .k-link {
        background-color: var.$accent;
    }

    .k-calendar.k-calendar-range .k-range-start,
    .k-calendar.k-calendar-range .k-range-end,
    .k-calendar.k-calendar-range .k-range-mid {
        background-image: linear-gradient(transparent 1px, mix.opaque(var.$accent, 25%) 1px,
                mix.opaque(var.$accent, 25%) calc(100% - 1px),
                transparent calc(100% - 1px))
    }

    .k-calendar.k-calendar-range .k-range-split-end::after {
        background-image: linear-gradient(to right, mix.opaque(var.$accent, 25%), transparent 100%);
    }

    .k-calendar.k-calendar-range .k-range-split-start::after {
        background-image: linear-gradient(to left, mix.opaque(var.$accent, 25%), transparent 100%);
    }

    .k-list .k-list-item.k-selected,
    .k-list .k-list-optionlabel.k-selected,
    .k-list-optionlabel.k-state-selected,
    .k-list-container .k-button:active,
    .k-list-container .k-button.k-state-active,
    .k-columnmenu-item.k-state-selected,
    .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected,
    .k-spreadsheet-popup .k-button:active,
    .k-spreadsheet-popup .k-button.k-state-active,
    .k-spreadsheet-popup .k-button.k-state-selected {
        background-color: var.$accent;
    }

    .k-textbox::selection:not(:focus),
    .k-input::selection:not(:focus),
    .k-textarea::selection:not(:focus) {
        background-color: #dcdcdc;
    }

    .k-list .k-list-item:hover.k-selected,
    .k-list .k-list-optionlabel:hover.k-selected,
    .k-list .k-item.k-state-hover.k-state-selected,
    .k-list-optionlabel:hover.k-state-selected,
    .k-list-optionlabel.k-state-hover.k-state-selected {
        background-color: var.$accent-lighter;
    }

    .hide-arrow.k-combobox .k-dropdown-wrap .k-select,
    .hide-arrow.k-dropdown .k-dropdown-wrap .k-select {
        display: none;
    }

    .hide-arrow.k-combobox .k-dropdown-wrap .k-clear-value,
    .hide-arrow.k-dropdown .k-dropdown-wrap .k-select {
        right: 8px;
    }

    .k-switch-on .k-switch-container {
        background-color: var.$accent;
    }

    .k-switch-on:hover .k-switch-container,
    .k-switch-on.k-state-hover .k-switch-container {
        background-color: var.$accent;
    }

    .k-input-inner {
        &::placeholder {
            opacity: 0.4;
        }

        &:-ms-input-placeholder {
            opacity: 0.4;
        }

        &::-ms-input-placeholder {
            opacity: 0.4;
        }
    }

    .k-required {
        color: red;
    }

    .k-textarea {
        border-color: mat.get-color-from-palette($foreground, divider);
    }

    .k-textarea:focus {
        outline-style: auto;
        outline-color: rgb(0 0 0 / 16%);
    }

    .k-textbox {
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        border-radius: 2px;
        padding: 4px 8px;
    }

    .k-time-footer {
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        margin: 0 !important;
        padding: 0;

        .k-time-cancel,
        .k-time-accept {
            padding: 12px 16px;
            border-radius: 0;
            border-width: 0;
            background: none;
            flex: 1 1 auto;
        }

        .k-time-accept {
            border-left-width: 1px;
            border-color: mat.get-color-from-palette($foreground, divider);
            color: var.$accent;
        }
    }

    .k-grid tr td {
        border: none;
    }
}