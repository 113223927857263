@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin color-helper($theme) {
    .primary-color {
        color: var.$primary;
    }
    .accent-color {
        color: var.$accent;
    }
    .warn-color {
        color: var.$warn;
    }

    .primary-highlight {
        box-shadow: 0px 0px 1px 3px var.$primary;
    }
    .accent-highlight {
        box-shadow: 0px 0px 1px 3px var.$accent;
    }
    .warn-highlight {
        box-shadow: 0px 0px 1px 3px var.$warn;
    }


    .primary-border {
        border-color: var.$primary!important;
    }
    .accent-border {
        border-color: var.$accent !important;
    }
    .warn-border {
        border-color: var.$warn !important;
    }

    .primary-background {
        background-color: var.$primary !important;
    }
    .accent-background {
        background-color: var.$accent !important;
    }
    .warn-background {
        background-color: var.$warn !important;
    }
    .bonus-background {
       background-color: var.$extra-green !important;
    }

    .primary-row {
        background-color: mix.opaque(var.$primary, 20%) !important;
    }
    .accent-row {
        background-color: mix.opaque(var.$accent, 20%) !important;
    }
    .warn-row {
        background-color: mix.opaque(var.$warn, 20%) !important;
    }
}
