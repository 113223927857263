@mixin media-query($size) {
    @if $size == 'xs' {
        @media screen and (max-width: 599px) {
            @content;
        }
    }

    @if $size == 'sm' {
        @media screen and (min-width: 600px) and (max-width: 959px) {
            @content;
        }
    }

    @if $size == 'md' {
        @media screen and (min-width: 960px) and (max-width: 1279px) {
            @content;
        }
    }

    @if $size == 'lg' {
        @media screen and (min-width: 1280px) and (max-width: 1919px) {
            @content;
        }
    }

    @if $size == 'xl' {
        @media screen and (min-width: 1920px) and (max-width: 5000px) {
            @content;
        }
    }

    @if $size == 'lt-sm' {
        @media screen and (max-width: 599px) {
            @content;
        }
    }

    @if $size == 'lt-md' {
        @media screen and (max-width: 959px) {
            @content;
        }
    }

    @if $size == 'lt-lg' {
        @media screen and (max-width: 1279px) {
            @content;
        }
    }

    @if $size == 'lt-xl' {
        @media screen and (max-width: 1919px) {
            @content;
        }
    }

    @if $size == 'gt-xs' {
        @media screen and (min-width: 600px) {
            @content;
        }
    }

    @if $size == 'gt-sm' {
        @media screen and (min-width: 960px) {
            @content;
        }
    }

    @if $size == 'gt-md' {
        @media screen and (min-width: 1280px) {
            @content;
        }
    }

    @if $size == 'gt-lg' {
        @media screen and (min-width: 1920px) {
            @content;
        }
    }
}

@mixin flex-width($width, $type: 'percent') {
    box-sizing: border-box;

    @if $type == 'percent' {
        flex: 1 1 100%;
        max-width: #{$width + '%'};
    }

    @if $type == 'px' {
        flex: 1 1 ($width) + px;
        max-width: ($width) + px;
        min-width: ($width) + px;
    }
}

@mixin flex-height($height, $type: 'percent') {
    box-sizing: border-box;

    @if $type == 'percent' {
        flex: 1 1 100%;
        max-height: #{$height + '%'};
    }

    @if $type == 'px' {
        flex: 1 1 ($height) + px;
        max-height: ($height) + px;
        min-height: ($height) + px;
    }
}

@mixin flex-responsive-width($width, $size) {
    @include media-query($size) {
        @include flex-width($width, 'percent');
    }
}

.flex-row {
    &.mat-card-content {
        display: flex;
    }

    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-start {
    display: flex;
    justify-content: flex-start;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-end {
    align-items: flex-end;
}

.items-stretch {
    align-items: stretch;
}

.self-start {
    align-self: flex-start;
}

.self-center {
    align-self: center;
}

.self-end {
    align-self: flex-end;
}

.flex-row-wrap {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-12 {
    gap: 12px;
}

.gap-15 {
    gap: 15px;
}

.gap-16 {
    gap: 16px;
}

.gap-20 {
    gap: 20px;
}

.gap-135 {
    gap: 135px;
}

.flex-display {
    display: flex;
}

.gap-em-1 {
    gap: 1em;
}

.gap-percent-1 {
    gap: 1%;
}

.gap-em-point-5 {
    gap: 0.5em;
}

.flex-0 {
    flex: 1 1 0%;
}

.flex-1 {
    flex: 1;
}

.flex-100 {
    flex: 1 1 100%;
}

.flex-noshrink {
    flex: 1 0 auto;
}

.flex-nogrow {
    flex: 0 1 auto;
}

.flex-none {
    flex: 0 0 auto;
}

.flex-max-content {
    flex: 1 1 100%;
    max-width: max-content;
}

@mixin flex-x {
    @for $i from 2 through 100 {
        .flex-#{$i} {
            @include flex-width($i);
        }
    }
}

@mixin flex-y {
    @for $i from 2 through 100 {
        .flex-height-#{$i} {
            @include flex-height($i);
        }
    }
}

@mixin gap-x {
    @for $i from 2 through 100 {
        .gap-#{$i} {
            gap: ($i) + px;
        }
    }
}

@include flex-x;
@include flex-y;
@include gap-x;
