@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin portal-grid($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .portal-grid {
        &.k-grid {
            background-color: transparent;
            border-width: 0;
            border-color: mat.get-color-from-palette($foreground, divider);
        }

        &.k-grid table {
            border-collapse: collapse;
        }
        &.k-grid thead  {
            background-color: mat.get-color-from-palette($background, card);
        }
        &.k-grid th {
            border-width: 0 0 1px 0;
        }
        &.k-grid td.k-state-selected,
        &.k-grid tr.k-state-selected>td {
            background-color: mix.opaque(var.$accent-lighter, 50%);
        }
        .k-grouping-row td {
            background-color: mat.get-color-from-palette($background, card);
        }
        tr .k-group-cell,
        tr.k-alt .k-group-cell {
            background-color: initial;
        }

        .k-grid-toolbar {
            background-color: transparent;
            border: 0;
            padding: 8px 0px;
        }

        .k-grid-aria-root {
            background-color: mat.get-color-from-palette($background, card);
            border-radius: 2px 2px 0 0;
            padding: 10px;
        }
        .k-grid-pager {
            align-items: center;
            background-color: mat.get-color-from-palette($background, card);
            border-radius: 0 0 2px 2px;
            border: 0;
            display: flex;
            flex: 0 0 auto;
            overflow: hidden;
            line-height: 1.4;
            padding: 8px;

            .k-pager-sizes {
                margin: 0 1em;
                font-weight: 700;
            }

            .k-pager-sizes .k-dropdownlist {
                margin: 0 8px 0 0;
                width: 5.2em;
            }
        }

        tbody a {
            color: var.$accent;
        }

        .k-checkbox:indeterminate,
        .k-checkbox.k-state-indeterminate {
            content: "—";
            color: var.$accent;
            background-color: mat.get-color-from-palette($background, card);
        }
        .k-checkbox:checked {
            background-color: var.$accent;
            border-color: var.$accent!important;
            &:focus {
                box-shadow: 0 0 0 2px rgba(var.$accent, 0.3);
            }
        }
        input[type=radio], input[type=checkbox] {
            margin: 0 0 !important;
            margin-right: 5px !important;
            line-height: 12px !important;
            border: solid 2px var.$primary-lighter !important;
            border-radius: 3px;
            background-color: mat.get-color-from-palette($background, card);
            width: 16px;
            height: 16px;
        }

        input.radio-style {
            border-radius: 50%;
            width: 18px;
            height: 18px;
        }

        .k-grid-aria-root,
        .k-grid-pager {
            box-shadow: 0 5px 10px -4px #d2d0cf;
        }
        .k-i-expand::before {
            content: "\e014";
        }
        .k-i-collapse::before {
            content: "\e013";
        }

        .portal-grid-flat & {
            .k-grid-aria-root,
            .k-grid-pager {
                box-shadow: none;
            }
            .k-grid-aria-root {
                padding: 0;
            }
        }

    }

    .highlighted-portal-grid{
        .portal-grid-header {
            background-color: var.$highlight !important;
            padding: 0 15px;
            height: 43px;
            padding-bottom: 0 !important;
            min-height: 51px !important;
            .title {
                font-weight: normal;
                color: var.$highlight-text;
            }
        }
    }

    portal-grid {
        display: block;
    }

    .portal-grid-vms {
        .k-grid .k-grid-aria-root {
            overflow: auto !important;
        }

        .k-grid {
            max-height: calc(100vh - 180px);
            color: var.$body-text;

            tr[role='row'] {
                td:first-child {
                    padding: .5rem .5rem .5rem 1rem;
                }
                td:last-child {
                    padding: .5rem 1rem .5rem .5rem;
                }
                td {
                    padding: .5rem .5rem;
                    &.centered {
                        text-align: center;
                    }

                    input.k-checkbox {
                        top: .125rem;
                    }
                }

                th[role='columnheader']:first-child {
                    padding: .5rem .5rem 1rem 1rem;
                }

                th[role='columnheader']:last-child {
                    padding: .5rem 1rem 1rem .5rem;
                }

                th[role='columnheader'] {
                    padding: .5rem .5rem 1rem .5rem;
                }
            }

            tr[role='row'].k-alt {
                background-color: var.$light-bg-neutral;
            }

            tr {
                cursor: default;
            }

            tr.k-master-row .mat-icon {
                top: .125rem;
                position: relative;
            }
        }

        .k-grid-header, .k-group-header, .k-grid-add-row, .k-grid-footer {
            color: var.$body-text;
        }

        .k-grid-aria-root {
            padding: .625rem 0 0 0;
        }

        .k-grid-toolbar {
            padding-top: 1rem;
            padding-bottom: 1rem;

            .mat-button-base {
                margin: 0 0.25em;
            }

            .mat-button-base:last-child {
                margin-right: 0px;
            }
        }

        .mat-toolbar-row, .mat-toolbar-single-row {
            height: auto;
        }
        .mat-toolbar-multiple-rows {
            min-height: 1rem;
        }

        &.highlighted-portal-grid {
            .portal-grid-header.mat-toolbar-multiple-rows {
                .title {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
            .mat-toolbar-row, .mat-toolbar-single-row {
                height: 4rem;
            }
        }

        .portal-grid-header, .portal-grid-header.mat-toolbar {
            padding: 0;
        }

        .k-filter-row {
            td {
                padding: .5rem 1rem;
            }
        }

        &.client-grid {
            >.k-grid  {
                color: var.$body-text;
            }
            .k-grid-header, .k-group-header, .k-grid-add-row, .k-grid-footer {
                color: var.$body-text;
            }
        }
    }
    

    .inner-toolbar {
        kendo-grid-toolbar.k-grid-toolbar {
            background-color: mat.get-color-from-palette($background, card);
            box-shadow: 0 5px 10px -4px #d2d0cf;
            border-radius: 2px 2px 0 0;
        }
        .k-grid-aria-root {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 18px;
                background-color: mat.get-color-from-palette($background, card);
                margin-top: -10px;
                z-index: 1;
            }
        }
    }
}
