@mixin shifts-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $highlight: map-get($theme, highlight);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .status-pendingApproval {
        $color: #f3be42;
        background: repeating-linear-gradient(
            115deg,
            rgba($color, 0.5) 0px,
            rgba($color, 0.5) 5px,
            rgba($color, 0.25) 5px,
            rgba($color, 0.25) 15px
        );
        border-color: $color;
    }

    .status-rejected {
        $color: #e04f4f;
        background: repeating-linear-gradient(
            115deg,
            rgba($color, 0.4) 0px,
            rgba($color, 0.4) 5px,
            rgba($color, 0.25) 5px,
            rgba($color, 0.25) 15px
        );
        border-color: $color;
    }

    .status-open,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-open,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-open:hover {
        $color: #f3be42;
        background-color: rgba($color, 0.25);
        border-color: $color;
    }

    .status-filled,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-filled,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-filled:hover {
        $color: #48927a;
        background-color: rgba($color, 0.25);
        border-color: $color;
    }

    .status-reconfirmed,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-reconfirmed,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-reconfirmed:hover {
        $color: #246a53;
        background-color: #91beaf;
        border-color: $color;
    }
    .status-pending,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-pending,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-pending:hover {
        $color: #E67A3A;
        background-color: #FFE2CC;
        border-color: $color;
    }
    .status-cancelledByClinician,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-cancelledByClinician,
    .portal-grid.k-grid.k-grid-md tr.k-table-row:not(.k-alt).status-cancelledByClinician:hover {
        $color: #6c6c6c;
        background-color: rgba($color, 0.25);
        border-color: $color;
    }
    
    .status-pending {
        $color: #E67A3A;
        background-color: #FFE2CC;
        border-color: $color;
    }
    .status-cancelledByClinician,
    .status-cancelledByFacility,
    .status-cancelledByHospital {
        $color: #e04f4f;
        background-color: rgba($color, 0.25);
        border-color: $color;
    }

    .label-badge {
        &.badge-agency {
            background-color: #8b72ce;
        }
        &.badge-pending {
            background-color: #f3be42;
        }
        &.badge-core-staff-tier {
            background-color: #48927A;
        }
        &.badge-float-pool-tier {
            background-color: #E67A3A;
        }
        &.badge-agency-tier {
            background-color: #f3be42;
        }
    }

    .label-badge:not(:first-child) {
        margin-left: 3px;
    }

    $schedules: '1' #a6dcdc, '2' #bda6dc, '3' #a6dca6, '4' #dcdba6, '5' #dca6d5, '6' #a6bedc, 'past' #bebebe;

    @each $name, $color in $schedules {
        .schedule-#{$name} {
            border-color: $color;
            background-color: transparentize($color, 0.65);
        }
    }

    .is-selected > td {
        color: #656565;
        background-color: rgba(255, 99, 88, 0.25);
    }
}
