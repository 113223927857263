$icon-size-md: 24px;
$icon-size-sm: 18px;

.icon {
    display: inline-block;
    vertical-align: middle;
    width: $icon-size-md;
    height: $icon-size-md;
    font-size: $icon-size-md;
    background-position: center center;
    background-size: 100% 100%;

    &-sm {
        height: $icon-size-sm;
        width: $icon-size-sm;
        font-size: $icon-size-sm;
    }

    &-shifts-app {
        background-image: url('/assets/icons/shifts-app-icon.svg');
        background-size: 100% 100%;
    }

    &-shifts-app-old {
        background-image: url('/assets/icons/shifts-app-icon.png');
        background-size: 90% 90%;
    }
}
