@use "domain" as *;

// Colors
$primary: var(--primary, #54565a);
$primary-lighter: var(--primary-200, #ccccce);
$primary-darker: var(--primary-800, #3a3c3f);
$primary-text: var(--primary-text, #ffffff);
$primary-lighter-text: var(--primary-lighter-text, #000000);
$primary-darker-text: var(--primary-darker-text, #ffffff);

$accent: var(--accent, #4690b0);
$accent-lighter: var(--accent-200, #c8dee7);
$accent-darker: var(--accent-800, #2f7397);
$accent-text: var(--accent-text, #ffffff);
$accent-lighter-text: var(--accent-lighter-text, #ffffff);
$accent-darker-text: var(--accent-darker-text, #ffffff);

$highlight: var(--highlight, #f88529);
$highlight-lighter: (--highlight-200, #fddabf);
$highlight-darker: var(--highlight-800, #f56819);
$highlight-text-active: var(--highlight-text-active, #ffffff);
$highlight-text: var(--highlight-text, #eeeeee);
$highlight-lighter-text: var(--highlight-lighter-text, #000000);
$highlight-darker-text: var(--highlight-darker-text, #ffffff);

$sidebar: var(--sidebar-color, #54565a);
$sidebar-lighter: var(--sidebar-lighter-color, #ccccce);
$sidebar-darker: var(--sidebar-darker-color, #3a3c3f);
$sidebar-text-active: var(--sidebar-text-active, #ffffff);
$sidebar-text: var(--sidebar-text, #D2D0CF);
$sidebar-lighter-text: var(--sidebar-lighter-text, #000000);
$sidebar-darker-text: var(--sidebar-darker-text, #ffffff);

$vendor-sidebar: var(--vendor-sidebar-color, #000000);
$vendor-sidebar-lighter: var(--vendor-sidebar-lighter-color, #000000);
$vendor-sidebar-darker: var(--vendor-sidebar-darker-color, #000000);
$vendor-sidebar-text-active: var(--vendor-sidebar-text-active, #ffffff);
$vendor-sidebar-text: var(--vendor-sidebar-text, #D2D0CF);
$vendor-sidebar-lighter-text: var(--vendor-sidebar-lighter-text, #ffffff);
$vendor-sidebar-darker-text: var(--vendor-sidebar-darker-text, #ffffff);

$card-background: var(--card-background, #c8dee74d);
$card-chevron: var(--card-chevron, #c8dee7);
$card-icon-background: var(--accent, #4690b0);
$card-icon-color: var(--text-dark-color, #ffffff);

$warn: var(--warn-color, #ff5252);
$warn-lighter: var(--warn-lighter-color, #ffcbcb);
$warn-darker: var(--warn-darker-color, #ff3838);
$warn-text: var(--warn-text, #ffffff);
$warn-lighter-text: var(--warn-lighter-text, #000000);
$warn-darker-text: var(--warn-darker-text, #ffffff);
$warn-background: var(--warn-background-color, #fef8ea);

$danger: var(--danger-color, #f88529);
$danger-lighter: (--danger-lighter-color, #fddabf);
$danger-darker: var(--danger-darker-color, #f56819);
$danger-text: var(--danger-text, #ffffff);
$danger-lighter-text: var(--danger-lighter-text, #000000);
$danger-darker-text: var(--danger-darker-text, #ffffff);

$warn-orange: #f3be42;
$warn-orange-lighter: #fbf6eb;
$warn-orange-darker: #ca920d;
$aya-orange: #f37d07;
$warn-background: #FEF8EA;
$warn-background-2: #FEE38C;

$secondary-medium-grey: #909194;

$lotusone-orange: #F15E22;
$success-new: #2B8C17;
$failure-new: #D80015;

$success: var(--success-color, #4caf50);
$success-lighter: var(--success-lighter-color, #c9e7cb);
$success-darker: var(--success-darker-color, #339637);
$success-text: var(--success-text, #ffffff);
$success-lighter-text: var(--success-lighter-text, #000000);
$success-darker-text: var(--success-darker-text, #ffffff);

$info: var(--info-color, #2a7393);
$info-lighter: var(--info-lighter-color, #50b1db);
$info-darker: var(--info-darker-color, #1e576f);
$info-text: var(--info-text, #ffffff);
$info-lighter-text: var(--info-lighter-text, #000000);
$info-darker-text: var(--info-darker-text, #ffffff);

$warn-2: var(--warn-alt-color, #f4d68f);
$warn-2-lighter: var(--warn-alt-lighter-color, #f4efe4);
$warn-2-darker: var(--warn-alt-darker-color, #868583);

$transparent-red: var(--transparent-red, #FFE8E4);
$destructive-red: var(--destructive-red, #E04F4F);

$body-text: var(--body-text, #424242);

// Light Background
$light-background: #f6f6f6;
$light-bg-neutral: #f5f5f5;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$light-bg-grey: var(--primary-row-light-grey-fafafb, #FAFAFB);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Dark Background
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.7);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Neutral text colors
$neutral-000: var(--color-neutral-000, #ffffff);
$neutral-001: var(--color-neutral-001, #f9fafa);
$neutral-002: var(--color-neutral-002, #f5f5f5);
$neutral-003: var(--color-neutral-003, #eaeaea);
$neutral-004: var(--color-neutral-004, #cdcdcd);
$neutral-005: var(--color-neutral-005, #b0b0b0);
$neutral-006: var(--color-neutral-006, #767676);
$neutral-007: var(--color-neutral-007, #606060);
$neutral-008: var(--color-neutral-008, #444444);
$neutral-009: var(--color-neutral-009, #222222);
$neutral-010: var(--color-neutral-010, #4C4C4C);

$spacer: 16px;

// Font Size
$font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
$font-size-headline: 20px;
$font-size-title: 18px;
$font-size-subheading-2: 16px;
$font-size-subheading-1: 15px;
$font-size-body: 14px;
$font-size-caption: 12px;
$font-size-badge: 0.75rem;

// Additional Colors
$extra-blue: #1b7197;
$extra-blue-alt: #0051e2;
$extra-orange: #e67a3a;
$extra-yellow: #f3be42;
$extra-green: #48927a;
$extra-red: #e04f4f;
$extra-grey: #6c6c6c;
$light-grey: #e9e9e9;
$transparent-blue: #ebf3f7;
$light-red: #efa7a7;
$primary-blue: #4690b0;

$link: var(--link-color, #4690b0);
$border-color: var(--border-color, #dddddd);
$border-color-2: var(--border-color-2, #dadada);
$active-menu-item: var(--active-menu-item, #fef3f6);

$header-height: 60px;
$message-text-color: #333333;
$legend-yellow: #ecbe5a;

// Shift coloring
$status-open: #FDE39D;
$status-filled: #B5D6AA;
$status-cancelled: #f9a79a;
$status-closed: #c0c0c0;
$status-closed-forecolor: #707070;

// Responsive
$responsive-desktop-breakpoint: 1366px;
$responsive-phone: 576px;
$responsive-tablet: 768px;
$responsive-notebook: 992px;
$responsive-desktop: 1281px;

// Lotus
$lotus-green: #008f47;
$lotus-text-color: #398439;

//Padding
$badge-padding: .35rem 0.50rem 0.25rem 0.50rem;

//Box-Radius
$badge-box-radius: .70rem;

$elevation-color: rgba(176, 176, 176, 0.35);

// expense status coloring
$expense-status-approved: rgba(72, 146, 122, 1);
$expense-status-approved-text: #ffffff;
$expense-status-pendingReview: rgba(70, 144, 176, 1);
$expense-status-pendingReview-text: #ffffff;
$expense-status-rejected: rgba(224, 79, 79, 1);
$expense-status-rejected-text: #ffffff;
$expense-status-pendingApproval: rgb(243, 190, 66);
$expense-status-pendingApproval-text: #ffffff;

//Tooltip
$tooltip-background: #444444;

// File Upload and Drag and Drop
$file-upload-container-border: 3px dashed var(--file-upload-container-border-color, #CFD8DC);
$file-upload-container-background-color: var(--file-upload-container-background-color, #ECEFF1);

// Top Nav Bg Color - needed for styling fixes for angular update
$toolbar-container-background-color: var(--toolbar-container-background-color, #FFFFFF);
