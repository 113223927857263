// To reduce usage of ng-deep for migrated pages, since looks can vary
// Use this stylesheet to break encapsulation
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42;
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: left;
}

table {
    border-collapse: collapse;
}

mat-form-field.vendor-profile-field {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

button.mat-flat-button.vendor-add-button {
    height: 24px;
    font-size: 12px;
    line-height: 12px;
}

button.mat-flat-button.vendor-add-button,
button.mat-flat-button.migrated-icon-button {
    padding: 0 12px 0 4px;

    .mat-icon {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
    }

    i,
    em {
        padding: 0 4px;
    }
}

button.delete-button {
    background-color: var.$warn;
    transform: scale(0.7);

    &:hover {
        background-color: mix.darken(var.$warn, 15%);
    }

    &:disabled {
        background-color: mix.lighten(var.$warn, 20%);
    }

    &.mat-icon-button {
        border-radius: 4px !important;
    }

    mat-icon {
        color: var.$warn-text;
    }
}

.panel-warning > .panel-heading {
    background-color: var.$highlight;
    border: 1px solid var.$highlight;
    color: var.$highlight-text-active;
    font-size: 18px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.migrated-section-container {
    background-color: var.$neutral-000;
    border: 1px solid transparent;
    border-color: var.$border-color;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    margin-bottom: 15px;
    padding: 15px;

    mat-expansion-panel.mat-expansion-panel {
        background-color: var.$light-background;
        margin-bottom: 6px;

        & .mat-expansion-panel-header {
            border: 1px solid var.$border-color;
        }
    }
}

mat-form-field.admin-jobs-select {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

mat-form-field.vendor-notes-form-field {
    .mat-form-field-outline {
        background-color: var.$neutral-000;
        border-radius: 6px;
    }

    &.notes--small.notes-select {
        .mat-form-field-wrapper {
            padding-bottom: 5px;
        }
    }

    &.notes--small {
        div.mat-form-field-subscript-wrapper {
            div.mat-form-field-hint-wrapper {
                align-items: baseline;

                mat-hint {
                    font-size: 0.9rem;
                }
            }
        }
    }

    div.mat-form-field-subscript-wrapper {
        padding: unset;
    }
}

ayac-portal-page-header.migrated-portal-page-header-toolbar {
    mat-toolbar.mat-toolbar.portal-page-header mat-toolbar-row.portal-page-header-toolbar {
        justify-content: unset;
    }
}

div.migrated-notes-bar {
    position: sticky;
    top: 10px;

    .mat-expansion-panel-body {
        margin: 0;
    }
}

.notes-expansion-color-height {
    background: linear-gradient(#b0b0b0 0, #8c8c8c 100%);
    height: 38px;
}

mat-expansion-panel.notes-expansion {

    // Handle hover cases and standard actions for expanding and collapsing
    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
    &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover,
    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']),
    &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']) &.mat-expansion-panel.mat-expanded .mat-expansion-panel-header:not([aria-disabled='true']),
    &.mat-expanded .mat-expansion-panel-header {
        @extend .notes-expansion-color-height;
    }

    & div.mat-expansion-panel-content {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 700px;

        div.mat-expansion-panel-body {
            padding: unset;
        }
    }

    &.mat-expansion-panel-header.mat-expanded:focus,
    &.mat-expansion-panel-header.mat-expanded:hover {
        @extend .notes-expansion-color-height;
    }

    mat-expansion-panel-header[role='button'] {
        color: white;

        mat-panel-title.mat-expansion-panel-header-title {
            color: inherit;
        }

        .mat-expansion-indicator::after {
            color: inherit;
        }
    }

    div.mat-expansion-panel-body {
        background-color: #ffcc99;
    }
}

.profile-tab-accordion {
    mat-panel-title.mat-expansion-panel-header-title {
        @include mix.font-15(400, #000000de);
    }

    .mat-expansion-panel-content {
        letter-spacing: normal;
    }
}

.phone-header>.mat-expansion-indicator:after,
.email-header>.mat-expansion-indicator:after,
.banks-header>.mat-expansion-indicator:after,
.websites-header>.mat-expansion-indicator:after,
.diversity-header>.mat-expansion-indicator:after {
    color: var.$accent;
    border-width: 0 3.5px 3.5px 0;
}

.mat-chip {
    min-height: 24px;
    padding: 12px 14px 12px 16px !important;
    background-color: #eeeeee;

    button {
        border: none;
        margin-top: -6px;
        margin-left: 0;
        background: none;
        transform: scale(0.8);
    }
}

.mat-chip-list-wrapper {
    .mat-standard-chip {
        margin: 0;
    }

    input.mat-input-element {
        border: none !important;
    }
}

mat-form-field.login-input {
    .mat-form-field-prefix {
        button[matprefix].mat-icon-button {
            height: unset;
            justify-content: unset;
            width: unset;
        }
    }

    .mat-form-field-infix {
        border-top: 0;
        padding-left: 5px !important;
    }
}

span.edit-container,
span.vendor-rate-display {
    display: flex;
    align-items: baseline;
    gap: 3px;

    mat-form-field {
        .mat-form-field-prefix {
            top: unset;
        }
    }
}

.panel {
    margin-bottom: 20px;
    background-color: var.$neutral-000;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px #0000000d;
}

.panel-default {
    border-color: var.$border-color;
}

.col-md-9 {
    padding-left: 15px;
    padding-right: 15px;
}

.widget-content {
    padding: 1em;
    margin: 0;
    height: inherit;
    overflow-y: visible;
    width: 100%;
}

.panel-body {
    padding: 15px;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;

    legend {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        font-size: 21px;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }
}

.mat-form-field-infix {
    padding: 4px 0 8px 0 !important;
}

label.control-label {
    font-size: 16px;
    font-family: Calibri, sans-serif;
    font-weight: bold;
    text-align: left;
    color: #005382;
}

button.mat-menu-item.admin-jobs-item {
    span.mat-badge-active.mat-badge-content {
        right: 0px;
        top: 0px;
    }
}

.document-panel {
    .mat-expansion-panel-body {
        border: 1px solid var.$border-color;
        border-top: 0;
        padding: 0;
        overflow: auto;
        max-height: 500px;
    }
}

.input-on-dark-background .mat-form-field-outline {
    background-color: white;
}

.paginator-with-page-buttons .mat-paginator-range-actions {
    .mat-paginator-range-label {
        margin: 0 0 0 24px !important;
        font-size: 14px;
        white-space: nowrap;
    }

    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next {
        display: none;
    }
}

.job-release-search-menu {
    div.mat-menu-content {
        padding-top: unset;
        padding-bottom: unset;
    }
}

.perdiem-scheduler-book-candidate-search-bar {
    background-color: var.$light-background;

    .mat-form-field-wrapper {
        padding: 0;
        background-color: white;
        width: 300px;
    }

    .mat-form-field {
        padding: 10px;
    }
}

.timecard-details {
    .mat-form-field .mat-form-field-wrapper {
        margin-bottom: -0.7em;
    }
}

.transparent-button:hover {
    text-decoration: underline;

    .mat-button-focus-overlay {
        opacity: 0 !important;
    }
}

.mat-tab-group.migrated-tab-label-active {
    .mat-tab-label-active {
        color: var.$accent;
        opacity: 1;
    }
}

.evaluator-information {
    .mat-radio-label-content {
        font-weight: normal;
    }
}

.migrated-tab-styles {
    &.mat-tab-group.mat-primary .mat-ink-bar {
        background-color: transparent;
        box-sizing: border-box;
        border: 1px solid var.$border-color;
        border-bottom-color: white;
        border-radius: 4px 4px 0 0;
        height: 32px;
        opacity: 1;
    }

    &.mat-tab-group * {
        transition: none !important;
        transition-duration: 0ms !important;
        transition-delay: 0ms !important;

        .mat-icon {
            margin-right: 8px;
            font-size: 18px;
            margin-top: 4px;
        }
    }

    .mat-tab-labels {
        border-bottom: 1px solid var.$border-color;
        margin-top: 14px;
    }

    .mat-tab-label {
        margin: -10px 0;
        justify-content: flex-start;
        opacity: 1 !important;
    }

    .mat-tab-label-content {
        color: var.$accent;
    }

    .mat-tab-nav-bar,
    .mat-tab-header {
        border-bottom: none;
        margin-bottom: 6px;
    }

    .mat-tab-label-active {
        background: white;

        .mat-tab-label-content {
            color: #555;
        }
    }
}

.non-bold {
    font-weight: normal;
    pointer-events: none;
}

.migrated-unit-description {
    pre {
        background-color: unset;
        border: unset;
        font: inherit;
        padding: 0 10px;
        white-space: pre-line;
    }

    .mat-select.mat-select-disabled.mat-select-invalid .mat-select-arrow {
        color: var.$warn !important;
    }

    .print {
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .shift-details {
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }
    }

    .shift-details tr.shift-row {
        .mat-form-field-wrapper {
            background-color: rgba(255, 255, 255, 0.84) !important;
            border-radius: 4px;
        }

        &.readonly .mat-form-field-wrapper {
            background-color: rgba(0, 0, 0, 0.06) !important;
            border-radius: 4px;
        }
    }
}

.reverse-invoices {
    .portal-grid .toolbar-spacer {
        flex: none !important;
        padding-left: 16px;
    }

    .toolbar-actions {
        width: 100%;
    }

    input[type="checkbox"]:focus {
        outline: 0;
        box-shadow: none !important;
    }
}

.kendo-grid-lookalike-multiselect-panel {
    $item-height: 32.8px;
    $select-all-height: 36px;
    $search-height: 46px;

    margin-top: 24px;
    margin-left: 34px;
    border-radius: 0 !important;
    min-width: calc(100% + 32px)!important;
    max-height: $select-all-height + $item-height * 8.5!important;

    &__has-search {
        max-height: $select-all-height + $item-height * 7.5 + $search-height!important;
    }

    mat-option.mat-option {
        padding-top: 8px;
        padding-bottom: 8px;
        height: unset;
        font-size: 14px;
        align-items: start;

        &.mat-active:not(:hover) {
            background: transparent;
        }
    }

    .mat-option-text.mat-option-text {
        white-space: normal;
        line-height: 1.2;
    }

    .mat-pseudo-checkbox-checked {
        background: var.$accent !important;
    }

    span.mat-checkbox-label {
        font-size: 14px;
        font-weight: normal !important;
    }

    .mat-checkbox-layout, .mat-checkbox-label {
        width: 100%;
    }

    .mat-checkbox-checked:not(.mat-checkbox-indeterminate) .mat-checkbox-background::after {
        top: 4.5px;
        left: 2.5px;
        width: 8px;
        height: 3px;
        border-left: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: rotate(-45deg);
        opacity: 1;
        color: white;
        box-sizing: content-box;
        position: absolute;
        content: "";
    }
    .mat-checkbox-checkmark {
        display: none;
    }
    .mat-checkbox .mat-checkbox-ripple {
        display: none;
    }
    .mat-checkbox-layout {
        margin-bottom: 0;
    }
    .mat-checkbox-layout .mat-checkbox-label {
        height: 36px;
        line-height: 36px;
    }
}

.kendo-grid-lookalike-multiselect {
    .mat-form-field-label-wrapper {
        font-size: 14px !important;

        .mat-form-field-label {
            top: 1.34375em;
        }
    }

    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline-thick {
        display: none;
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
        color: rgba(0, 0, 0, 0.08);
    }

    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
        opacity: 1 !important;
        background-color: #ebebeb;
    }

    .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
    }

    .mat-form-field-infix {
        height: 32px;
        border-top-width: 8px;
    }

    .mat-form-field-outline {
        color: #0000001f;
    }

    .mat-select {
        top: 0;
        position: absolute;
    }

    &.kendo-grid-lookalike-multiselect-clear .mat-select {
        padding-right: 16px;
    }

    .mat-select-arrow-wrapper {
        display: none;
    }

    .mat-select-placeholder {
        font-size: 14px;
        color: rgba(0,0,0,.87);
    }

    .mat-select-value-text {
        font-size: 14px !important;
        font-weight: normal;
    }

    &.kendo-grid-lookalike-multiselect-inline {
        .mat-select {
            padding: 4px 0;
        }

        .mat-form-field-infix {
            height: 32px;
            border-top-width: 8px;
        }
        .k-button-icon {
            top: -10px;
            font-size: 16px;
            line-height: 1;
            right: -4px;
            width: 16px;
            height: 16px;
        }
    }
}

.kendo-grid-lookalike-multiselect-searchable {
    .mat-form-field-outline {
        background-image: unset;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: white;
        }
    }
    .mat-select-placeholder {
        color: #b0b0b0;
    }
    .mat-form-field-infix {
        border-top-width: 0.84375em
    }
    .mat-form-field-flex {
        align-items: center;
    }
    .mat-form-field-suffix {
        margin-left: 4px;
    }
    .k-button-icon {
        top: -2px!important;
        right: -2px!important;
    }
    .mat-form-field-outline-end:after  {
        content: '';
        position: absolute;
        top: 1px;
        background-color: rgba(0, 0, 0, 0.0392);
        display: block;
        width: 30px;
        bottom: 1px;
        right: 1px;
        border-left: 1px solid rgba(0,0,0,0.08);
    }
    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-outline {
        color: rgba(0, 0, 0, 0.08);
    }
    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover {
        .mat-form-field-outline {
            color: rgba(0, 0, 0, 0.16);
        }
        .mat-form-field-outline-end:after {
            background-color:#ebebeb;
        }

    }
}

.client-submittal-details-status-menu.mat-menu-panel {
    max-width: 400px !important;
}



.multifield-multiselect-panel {

    .mat-pseudo-checkbox {
        display: none;
    }
}

.fa-asterisk.fa-asterisk-large {
    font-size: 180%;
    vertical-align: middle;
    color: var.$aya-orange;
}

.mat-checkbox-simple {
    .mat-checkbox-label {
        font-weight: normal;
    }
}

.remove-mat-field-bottom-padding {
    .mat-form-field-wrapper {
        margin-bottom: -1.25em !important;
     }
}
