@use '@angular/material' as mat;
@use '../palette' as pal;
@use 'src/styles/variables' as var;

@mixin text-helper($theme) {
    $font-size: mat.font-size(pal.$fontConfig, body-1);

    .text-sm {
        font-size: $font-size * 0.8;
    }

    .text-md {
        font-size: $font-size * 1.2;
    }

    .text-lg {
        font-size: $font-size * 1.4;
    }

    .text-xlg {
        font-size: $font-size * 2.8;
    }

    .text-bold {
        font-weight: bold;
    }

    .text-normal {
        font-weight: normal;
    }

    .link {
        color: var.$link;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
