@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin core-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $highlight: map-get($theme, highlight);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .menu-item {
        color: var.$sidebar-text;

        &__icon {
            background-color: var.$sidebar-text;
        }

        &__content {
            border-color: var.$sidebar-text;
        }

        &__content:hover {
            border-color: mix.lighten(var.$sidebar, 40%);
        }
        &--hovered {
            .menu-item__content {
                border-color: mix.lighten(var.$sidebar, 40%);
            }
        }

        &--module-active {
            color: var.$highlight-text-active;
        }
        .menu-item__content {
            border-color: transparent;
        }
        &--module-active .menu-item__content {
            border-color: var.$highlight;
        }

        &--item-active {
            background-color: var.$active-menu-item;
        }
    }

    .menu-popup__panel {
        background-color: #fff;
    }

    .menu-popup__heading {
        color: var.$primary;
    }
}
