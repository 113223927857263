@use 'src/styles/_mixins' as mix;
@use 'variables' as var;

.portal-grid {
    .k-grid-toolbar {
        display: flex;
        align-items: flex-end;

        .mat-button-base {
            margin: 0 0.3em;
        }
    }

    .toolbar-actions {
        order: 2;
        display: flex;
        flex-direction: row;
    }
    .toolbar-actions-no-spacer {
        width: 100%;
    }
    .toolbar-spacer {
        flex: 1 auto;
        order: 0;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    margin-bottom: 1px;

    tr {
        cursor: pointer;
    }

    td.no-overflow {
        overflow: unset;
    }
}

.portal-grid-scroll-auto {
    .k-grid .k-grid-aria-root {
        overflow: auto !important;
    }
}
.portal-grid-pointer {
    tr {
        cursor: pointer;
    }
}
.portal-grid-pointerless {
    tr {
        cursor: unset;
    }
}

.portal-grid-toolbar .mat-toolbar-row {
    align-items: center;
}
.k-pdf-export th .k-icon {
    display: none;
}

.main-portal-invoices {
    .mat-icon {
        vertical-align: initial !important;
    }

    portal-grid .k-grid-toolbar {
        display: flex;
        align-items: center;

        .mat-button-base {
            margin: 0 0.3em;
        }
    }

    portal-grid .toolbar-actions {
        position: relative;
        margin: -35px auto 0px;
        order: 2;
        display: flex;
        flex-direction: row;
    }
    portal-grid .toolbar-spacer {
        flex: 1 auto;
        order: 0;
    }

    portal-grid .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}


.custom-multiselect-grid-filter-max-height {
    max-height: 60px;
    overflow-y: auto;
}

.custom-multiselect-grid-filter-max-height-expanded {
    max-height: 150px;
    overflow-y: auto;
}

.pinned-row {
    background-color: #E9E9E9 !important;
    border-bottom: 1px solid #C5C5C5;

    .pinned-icon-cell {
        padding-left: 20px;
    }

    .pin-icon {
        color: var.$primary;
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0%, -50%);
        @include mix.mat-icon-size(16px);
    }
}


grid-multiselect-filter .single-line-chips {
    .k-chip {
        max-width: 160px;
    }
    .k-chip-label,
    .k-chip-label.k-text-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .k-input-inner {
        min-width: 25px;
    }
}
.portal-grid {
    .k-hierarchy-cell {
        .k-i-plus::before {
            content: "\e015";
        }
        .k-i-minus::before {
            content: "\e013";
        }
        & > .k-icon {
            padding-block: 8px;
            padding-inline: 8px;
        }
    }
}

grid-multiselect-filter .has-hidden-label {
    .k-chip-list .k-chip:last-of-type {
        background: none;
        border: none;
        padding: 0;

        .k-chip-actions {
            display: none;
        }
        .k-chip-content {
            margin-inline-end: 0;
            margin-inline-start: 2px;
        }
        .k-chip-label {
            color: var.$body-text;
        }
    }
}

.k-grid .no-overflow.k-header .k-cell-inner,
.k-grid .no-overflow.k-header .k-cell-inner > .k-link {
    overflow: unset;
}