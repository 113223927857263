@use '@angular/material' as mat;
@use 'src/styles/variables' as var;

@mixin badge($theme) {
    .label-badge {
        border-radius: 5px;
        padding: 0.25em 0.6em;
        line-height: 1;
        background-color: var.$primary;
        font-size: 0.8em;
        color: #fff;
        vertical-align: middle;
        display: inline-block;

        &.badge-primary {
            background-color: var.$primary;
        }

        &.badge-accent {
            background-color: var.$accent;
        }

        &.badge-warn {
            background-color: var.$warn;
        }

        &.badge-highlight {
            background-color: var.$highlight;
        }

        &.bardge-circle {
            border-radius: 1em;
        }
    }
}
