@use '@angular/material' as mat;
@use 'src/styles/_mixins' as mix;
@use 'src/styles/variables' as var;

@mixin scroll-helper($theme) {
    .scroll-accent {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background: var.$accent;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:active {
            background: mix.darken(var.$accent, 10%);
        }

        &::-webkit-scrollbar-track {
            background: #e1e1e1;
            border: 0px none #ffffff;
            border-radius: 0px;
        }

        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}
